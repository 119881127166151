'use client'

import dynamic from 'next/dynamic'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { CancelTwoTone as CancelTwoToneIcon, CheckCircleTwoTone as CheckCircleTwoToneIcon } from '@mui/icons-material'

// Types
import type { DialogProps } from '@/types/components/molecules/dialog'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

// Components
const LoadingButtonAtom = dynamic(() => import('@/components/atoms/buttons/text/loading'))
const OutlinedButtonAtom = dynamic(() => import('@/components/atoms/buttons/text/outlined'))

const DialogMolecule = (props: DialogProps) => {
	// Props
	const { lng, type, title, description, confirm, close, loading, open, fullScreen, setOpen, confirmAction, children } = props

	// Variables
	const { t } = useTranslation(lng)

	return (
		<Dialog
			open={open}
			fullScreen={fullScreen === true}
			onClose={() => setOpen(false)}
			PaperProps={{
				...(type === 'form' && {
					component: 'form',
					encType: 'multipart/form-data'
				}),
				...(fullScreen === false && {
					sx: {
						width: '90dvw',
						maxWidth: '90dvw'
					}
				})
			}}
		>
			{title && <DialogTitle>{t(title)}</DialogTitle>}

			{(description || children) && (
				<DialogContent sx={{ pb: 1 }}>
					{description && <DialogContentText>{t(description)}</DialogContentText>}
					{children}
				</DialogContent>
			)}

			<DialogActions>
				{close && <OutlinedButtonAtom lng={lng} text={close} size="small" color="error" startIcon={<CancelTwoToneIcon />} onClick={() => setOpen(false)} />}

				{confirm && (
					<LoadingButtonAtom
						lng={lng}
						type={type === 'form' ? 'submit' : undefined}
						text={confirm}
						size="small"
						color="success"
						loading={loading}
						startIcon={<CheckCircleTwoToneIcon />}
						onClick={confirmAction}
					/>
				)}
			</DialogActions>
		</Dialog>
	)
}

export default DialogMolecule
